import React from 'react';
import SyntaxHighlighter, {
  registerLanguage
} from 'react-syntax-highlighter/prism-light';
import html from 'react-syntax-highlighter/languages/prism/markup';
import prism from 'react-syntax-highlighter/styles/prism/prism';

import requestFlow from './request-flow.png';

registerLanguage('html', html);

const Faq = () => (
  <article itemProp="text">
    <div className="copy readable-width">
      <h2 id="#what-is-it">What is it?</h2>

      <p>
        Fastly Insights is an optional service deployed by some Fastly customers
        for network and performance monitoring and research purposes. It does
        not collect any personal data. We are only interested in your network,
        to make the internet work better.
      </p>
      <p>
        We collect information about HTTP and HTTPS network transactions,
        including: network routing, performance timing, and equipment
        characteristics. Measurements are recorded to analyze the performance of
        the Fastly network and overall state of the internet.
      </p>
      <p>
        Insights.js is served via Fastly’s CDN. All collected data is sent back
        to the Fastly Insights service and log streamed using Fastly’s{' '}
        <a href="https://docs.fastly.com/guides/streaming-logs/" rel="nofollow">
          log streaming
        </a>{' '}
        to a Fastly managed data warehouse for subsequent analysis.
      </p>

      <h2 id="#how-does-it-work">How does it work?</h2>

      <p>
        The library is deployed to websites via a JavaScript{' '}
        <code>&lt;script&gt;</code> tag, such as:
      </p>

      <SyntaxHighlighter language="html" style={prism}>
        {
          '<script defer src="https://www.fastly-insights.com/insights.js?k=1111-2222-3333"></script>'
        }
      </SyntaxHighlighter>

      <p>
        In the above tag, <code>k</code> is an API token that identifies the
        host website to Fastly. The script contains code to load and execute a
        minimal task runner application. All tasks are run as low-priority
        requests and are designed not to interfere with the user's current page
        navigation or alter the host page’s{' '}
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model"
          rel="nofollow"
        >
          Document Object Model (DOM)
        </a>{' '}
        in any way, to prevent it from accessing first-party data on the page or
        affecting page load performance.
      </p>
      <p>
        Each task fetches one or more objects from the network and gathers
        timing information associated with the request (using the{' '}
        <a href="https://w3c.github.io/resource-timing/" rel="nofollow">
          ResourceTiming API
        </a>) and any other browser information required by the task. See{' '}
        <a href="#what-type-of-information-does-fastly-insights-collect">
          below
        </a>{' '}
        for the full list of task types.
      </p>
      <p>
        The results of each task are normalized (such as IP anonymization, see{' '}
        <a href="#what-type-of-information-does-fastly-insights-collect">
          full list of task types
        </a>{' '}
        for further information regarding normalization) and the data is then
        beaconed back to the Fastly Insights service via a POST request to
        fastly-insights.com/beacon.
      </p>
      <p>
        The service then adds additional data available from Fastly’s{' '}
        <a
          href="https://docs.fastly.com/guides/streaming-logs/useful-variables-to-log"
          rel="nofollow"
        >
          standard logging variables
        </a>{' '}
        related to the network request and logs all final data to a Fastly
        managed data warehouse.
      </p>

      <h2 id="#request-flow">Request flow</h2>

      <p>
        <img
          src={requestFlow}
          alt="Fastly Insights.js request flow"
          style={{ maxWidth: '100%' }}
        />
      </p>

      <ol>
        <li>Page load.</li>
        <li>Fetches configuration from Fastly Insights service.</li>
        <li>
          Tasks are executed (see also:{' '}
          <a href="#what-type-of-information-does-fastly-insights-collect">
            full list of task types
          </a>{' '}
          and data collected):
          <ol>
            <li>Network requests made to test objects.</li>
            <li>Task information recorded on the client.</li>
          </ol>
        </li>
        <li>
          Task information is beaconed back to the Fastly Insights service.
        </li>
        <li>
          Additional information is recorded at the Fastly Insights service
          before ingestion.
        </li>
        <li>
          Information collected in step 3 and 5 is logged to a Fastly managed
          data warehouse for post-processing.
        </li>
      </ol>

      <h2 id="#what-type-of-information-does-fastly-insights-collect">
        What type of information does Fastly Insights collect?
      </h2>

      <p>
        The following table lists each of the possible tasks Fastly Insights may
        run on a host web page:
      </p>
    </div>
    <table className="fui-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Client data*</th>
          <th>Request metadata*</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>POP</td>
          <td>
            Intended to measure the latency and topology of client connections
            to Fastly’s point of presence (POP) data centers.
          </td>
          <td>
            <ul>
              <li>
                <a
                  href="https://w3c.github.io/resource-timing/#performanceresourcetiming"
                  rel="nofollow"
                >
                  network timing
                </a>
              </li>
              <li>network characteristics</li>
              <li>
                browser type (<a
                  href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent"
                  rel="nofollow"
                >
                  User-Agent header
                </a>{' '}
                value is automatically normalized to browser vendor and version)
              </li>
              <li>DNS recursive resolver</li>
              <li>operating system</li>
              <ul />
            </ul>
          </td>
          <td>
            <ul>
              <li>
                anonymized Internet Protocol (IP) addresses (client IP addresses
                are automatically truncated to a /28 network prefix for IPv4 and
                /58 for IPv6 addresses)
              </li>
              <li>country or city-level geographic location</li>
              <li>date/time stamps</li>
              <li>network characteristics unique to the client connection</li>
              <li>browser capabilities: TLS protocol and cipher suites</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Fetch</td>
          <td>
            Intended to measure the performance characteristics of a Fastly Insights owned HTTP endpoint. For experimentation and diagnostic purposes.
          </td>
          <td>
            <ul>
              <li>
                <a
                  href="https://w3c.github.io/resource-timing/#performanceresourcetiming"
                  rel="nofollow"
                >
                  network timing
                </a>
              </li>
              <li>
                <a
                  href="https://w3c.github.io/server-timing/"
                  rel="nofollow"
                >
                  server timing
                </a>
              </li>
              <li>network characteristics</li>
              <li>
                browser type (<a
                  href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent"
                  rel="nofollow"
                >
                  User-Agent header
                </a>{' '}
                value is automatically normalized to browser vendor and version)
              </li>
              <li>DNS recursive resolver</li>
              <li>operating system</li>
              <ul />
            </ul>
          </td>
          <td>
            <ul>
              <li>
                anonymized Internet Protocol (IP) addresses (client IP addresses
                are automatically truncated to a /28 network prefix for IPv4 and
                /58 for IPv6 addresses)
              </li>
              <li>country or city-level geographic location</li>
              <li>date/time stamps</li>
              <li>network characteristics unique to the client connection</li>
              <li>browser capabilities: TLS protocol and cipher suites</li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4">
            <p>
              <strong>*Note:</strong> <em>Client data</em> is collected on the
              client within a browser and <em>Request metadata</em> is collected
              on the Fastly Insights service.
            </p>
          </td>
        </tr>
      </tfoot>
    </table>

    <div className="copy readable-width">
      <h2 id="#what-does-fastly-use-fastly-insights-information-for">
        What does Fastly use Fastly Insights information for?
      </h2>

      <p>
        Fastly uses the data collected to identify trends and performance
        heuristics for clients interacting with Fastly and its services.
        Fastly's use, and potential disclosure, of the data includes:
      </p>
      <ul>
        <li>Monitoring Fastly’s network and performance</li>
        <li>Improving the accuracy of DNS query answers</li>
        <li>Improving Fastly's capacity and network planning</li>
        <li>
          A/B testing and comparison of the performance of new technologies to
          improve Fastly services
        </li>
        <li>Research initiatives to inform technology decisions</li>
        <li>Research initiatives to inform case studies</li>
        <li>
          Research initiatives for academic purposes and to feed back to the
          wider community
        </li>
        <li>
          Responding to performance and other related inquiries from Fastly’s
          customers
        </li>
      </ul>

      <h2>Privacy concerns</h2>

      <p>
        As described above in this FAQ, the information collected is statistical
        data and does not include personally identifiable data. Client IP
        addresses are truncated, and user-agent strings normalized before
        ingestion. See the{' '}
        <a href="#what-type-of-information-does-fastly-insights-collect">
          full list of task types
        </a>{' '}
        for a list of data collected.
      </p>
      <p>
        Fastly Insights does not read or write any data to persistent storage in
        the browser, which includes cookies. Fastly Insights does not interact
        with cookies. We do not store any information across browsing sessions.
      </p>
      <p>
        We may retain the raw information collected from individual Fastly
        Insights sessions for up to one year. We may retain aggregate
        information indefinitely.
      </p>

      <h2>Fastly’s commitment to transparency</h2>
      <p>
        Any changes to this FAQ document and the data we collect over time will
        be logged in our{' '}
        <a href="https://github.com/fastly/insights.js/blob/master/CHANGELOG.md">
          change log
        </a>.
      </p>

      <h2>License</h2>
      <p>
        <a href="https://github.com/fastly/insights.js/blob/master/LICENSE">
          MIT
        </a>
      </p>
    </div>
  </article>
);

export default Faq;
