import React from 'react';
import { Helmet } from 'react-helmet';
import { push as navigate } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { APP_URL, APP_TIMEOUT } from '../../constants/env.js';

import { runTest } from '../../actions/TaskActions.js';
import Hero from '../../components/Hero/Hero.js';
import Faq from '../../components/Faq/Faq.js';

const Home = ({ isrunTesting, runTest, test, signup }) => {
  const tryBtnAction = () => {
    runTest(APP_URL, APP_TIMEOUT);
    test();
  };
  const signupBtnAction = () => {
    signup();
  };
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Real user monitoring of network and performance characteristics to help make the internet faster."
        />
      </Helmet>
      <Hero tryBtnAction={tryBtnAction} signupBtnAction={signupBtnAction} />
      <div className="container">
        <Faq />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isrunTesting: state.tasks.isrunTesting
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      runTest,
      test: () => navigate('/test'),
      signup: () => navigate('/signup')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
