import React from 'react';

import Particles from '../Particles/Particles.js';

const Hero = ({ tryBtnAction, signupBtnAction }) => {
  return (
    <div className="hero background--grey-darker margin-bottom--xl">
      <Particles height="400px" />
      <div className="hero__content container">
        <div>
          <h1 className="hero__title margin-bottom--m">Fastly Insights</h1>
          <p className="margin-bottom--xl">
            Real user monitoring of network and performance characteristics to
            help make the internet faster.
          </p>
          <button className="accent" onClick={() => tryBtnAction()}>
            Try it now
          </button>
          <button className="primary" onClick={() => signupBtnAction()}>
            Sign up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
