import React, { Component } from 'react';
import ParticlesComp from 'react-particles-js';

import { DESKTOP_BREAKPOINT } from '../../constants/env.js';
import { isRetina, getViewportDimensions } from '../../util/dom.js';

const viewportWidth = getViewportDimensions().width;

const params = {
  particles: {
    number: {
      value:
        viewportWidth < DESKTOP_BREAKPOINT
          ? isRetina
            ? 10
            : 15
          : isRetina
            ? 25
            : 40
    },
    color: {
      value: '#ffffff'
    },
    opacity: {
      value: 1,
      random: true
    },
    size: {
      value: 1.6,
      random: true
    },
    line_linked: {
      enable: true,
      distance: 190,
      color: '#ffffff',
      opacity: 0.4,
      width: 0,
      shadow: {
        blur: 5
      }
    }
  },
  retina_detect: true
};

export default class Particles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 370
    };
  }

  updateDimensions() {
    const height = this.container.innerHeight;
    this.setState({ height: height });
  }

  componentDidMount() {
    this.container = document.querySelector('.hero');
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  render() {
    return <ParticlesComp params={params} height={this.state.height} />;
  }
}
